import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../assets/style/colors";
import TrackingComponent from "../components/Tracking/TrackingComponent";

const TermsPage = () => {
  return (
    <Container>
      <LeftSide>
        <h1>Terms of Service</h1>
      </LeftSide>

      <RightSide>
        <section>
          <h1>Introduction</h1>
          <p>
            These Terms & Conditions ("Terms") are entered into by and between
            Grovs, Inc. ("Grovs," "we," or "us") and the entity or person
            accessing this website ("Website") or placing an order for or
            accessing any Services made available by Grovs ("Customer," "you,"
            or "your"). "Services" means the products and services made
            available online by Grovs, including "Beta Services" and other
            services that Grovs may make available from time to time.
          </p>

          <h1>Agreement Acceptance</h1>
          <p>
            By using the Services, you agree to be legally bound by these Terms.
            If you are entering this Agreement on behalf of an entity, you
            represent that you have the authority to bind such entity and its
            affiliates to this Agreement.
          </p>

          <h1>Definitions</h1>
          <p>
            <strong>"Affiliate"</strong> means any entity that controls, is
            controlled by, or is under common control with the subject entity.
            <br />
            <strong>"Agreement"</strong> means these Terms, any applicable Order
            Forms, and any amendments mutually agreed to by the Parties.
            <br />
            <strong>"Beta Services"</strong> means Grovs’s services or
            functionality that are not generally available to customers and are
            designated as beta, pilot, or evaluation.
            <br />
            <strong>"Collection Technologies"</strong> means any technology used
            by Grovs to support the provision of the Services.
            <br />
            <strong>"Digital Properties"</strong> means your mobile
            applications, websites, and program code created by or for you that
            use the Services.
            <br />
            <strong>"Documentation"</strong> means the applicable Service’s
            documentation and usage guidelines.
            <br />
            <strong>"Order Form"</strong> means an ordering document specifying
            the Services to be provided.
          </p>

          <h1>Service Access and Availability</h1>
          <p>
            Grovs will make the Services available to you for internal business
            needs pursuant to these Terms and the applicable Documentation.
          </p>

          <h1>Use Restrictions; Updates & Support</h1>
          <p>
            You must comply with all applicable laws and use the Services only
            as permitted under these Terms. Grovs will maintain, support, and
            update the Services as provided to its customers generally.
          </p>

          <h1>Beta Services</h1>
          <p>
            Beta Services are for evaluation purposes only and not for
            production use. Grovs may discontinue Beta Services at any time and
            will not be liable for any harm or damage arising out of their use.
          </p>

          <h1>Payment Terms</h1>
          <p>
            <strong>Fees:</strong> You will pay all fees specified in an Order
            Form.
            <br />
            <strong>Payments & Invoicing:</strong> Invoices will be sent via
            email, and you must pay the Fees upon receipt.
            <br />
            <strong>Payment by Credit Card:</strong> Credit card payments may
            incur transaction fees.
            <br />
            <strong>Payment Disputes:</strong> Disputes must be submitted in
            writing within 90 days.
            <br />
            <strong>Suspension or Cancellation:</strong> Grovs may suspend or
            cancel Services for overdue payments.
            <br />
            <strong>Taxes:</strong> Fees do not include any taxes, which you are
            responsible for paying.
          </p>

          <h1>Termination</h1>
          <p>
            Grovs may terminate this Agreement for material breach with a 30-day
            notice. All outstanding balances become immediately due upon
            termination.
          </p>

          <h2>Termination by Customer</h2>
          <p>
            You may terminate your access to and use of the Services at any time
            by providing written notice to Grovs
            <a href="http://grovs.io/contact">http://grovs.io/contact</a>, or
            its successor URL. Such termination will be effective as of the last
            day of the calendar month in which your termination notice is
            received by Grovs. Upon termination by you, all outstanding balances
            and any other unpaid payment obligations will become immediately due
            and payable in full.
          </p>

          <h2>Survival</h2>
          <p>
            Upon termination of the Agreement, certain sections of these Terms
            will survive, including but not limited to those on Termination,
            Privacy Policy & Data Rights, Confidential Information, Proprietary
            Rights, Limitation of Liability, and Legal Notices.
          </p>

          <h1>Privacy Policy & Data Rights</h1>
          <p>
            <strong>Privacy Policy:</strong> You agree to provide a compliant
            privacy policy to your end users, detailing your privacy practices
            including use of cookies and data handling. Grovs’s Privacy Policy
            does not cover your Digital Properties. You acknowledge Grovs's
            reliance on your compliance for data protection laws.
          </p>
          <p>
            <strong>Data Analytics:</strong> Grovs may collect and process data
            from your Digital Properties as outlined in its Privacy Policy to
            optimize the Services.
          </p>
          <p>
            <strong>Data Security:</strong> Grovs will implement safeguards to
            protect information, although absolute security cannot be
            guaranteed.
          </p>
          <p>
            <strong>Compliance with Applicable Law:</strong> You must comply
            with all relevant laws, including GDPR and CCPA, regarding data
            collection, consent, and transfer. You agree not to share sensitive
            or personally identifiable information (PII) with Grovs unless
            legally permitted.
          </p>

          <h2>Disclaimer</h2>
          <p>
            Except as expressly provided in the Agreement, the Services, and the
            Documentation are provided on an "as is" basis, without
            representation or warranty of any kind. Grovs explicitly disclaims
            all warranties, including but not limited to warranties of
            merchantability, fitness for a particular purpose, quiet enjoyment,
            non-infringement, and any warranties arising out of course of
            dealing, course of performance, or usage of trade. Grovs does not
            warrant the accuracy, completeness, or usefulness of the Services
            and the Documentation, nor does Grovs warrant that the Services will
            be free from errors or that the operations of the Services will be
            uninterrupted. You use the Services and the Documentation at your
            own risk. Some jurisdictions do not allow the exclusion of certain
            warranties under certain circumstances, so the above limitations may
            not apply to you to the extent prohibited by applicable law.
          </p>

          <h2>Limitation of Liability</h2>
          <p>
            Grovs, its suppliers, and licensors will not be liable for any
            indirect, incidental, special, punitive, consequential, or exemplary
            damages, including but not limited to damages for loss of profits,
            loss of revenue, goodwill, use, data that is incomplete, missing, or
            incorrect, or other intangible losses, even if Grovs has been
            advised of the possibility of such damages, resulting from this
            Agreement.
          </p>
          <p>
            Under no circumstances will the total aggregate liability of Grovs
            and its suppliers and licensors resulting from this Agreement
            (including warranty claims), regardless of the forum and regardless
            of whether any action or claim is based in contract, warranty,
            negligence, strict liability, or other tort, breach of any statutory
            duty, indemnity or contribution, or otherwise, exceed the amounts,
            if any, that you have paid or agreed to pay to Grovs for use of the
            Services in the twelve (12) month period prior to the event giving
            rise to the claim. Some jurisdictions do not allow the exclusion or
            limitation of liability for incidental or consequential damages, so
            the above limitation may not apply to you to the extent prohibited
            by applicable law.
          </p>
        </section>
      </RightSide>
      <TrackingComponent></TrackingComponent>
    </Container>
  );
};

export default TermsPage;

const Container = styled.div`
  width: 100%;
  max-width: 1512px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 80px;
  @media (max-width: 990px) {
    padding: 20px;
    gap: 50px;
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 48px;
    font-weight: 700;
    line-height: 57.6px;
    text-align: left;
  }
`;
const RightSide = styled(LeftSide)`
  gap: 60px;

  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
  }
  p {
    color: rgba(48, 44, 44, 0.7);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  section {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  @media (max-width: 990px) {
    gap: 40px;
    h1 {
      font-size: 24px;
      line-height: 32px;
    }
    p {
      font-size: 16px;
      line-height: 20px;
    }
    section {
      gap: 20px;
    }
  }
`;
