import React from "react";
import styled from "styled-components";
import PricingPlans from "../components/PricingComponents/PricingPlans";
import PricingFaq from "../components/PricingComponents/PricingFaq";
import TrackingComponent from "../components/Tracking/TrackingComponent";

const PricingPage = () => {
  return (
    <Container>
      <PricingPlans />
      <PricingFaq />
      <TrackingComponent></TrackingComponent>
    </Container>
  );
};

export default PricingPage;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
