import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/style/colors";
import { ReactComponent as Asset1 } from "../../assets/imgs/fold4Asset1.svg";
import { ReactComponent as Asset2 } from "../../assets/imgs/fold4Asset2.svg";
import { ReactComponent as Asset3 } from "../../assets/imgs/fold4Asset3.svg";
import deeplinkImage from "../../assets/imgs/solutions-deeplink.jpg";
import crossPlatformLinking from "../../assets/imgs/cross-platform-linking.jpg";
import attributionTracking from "../../assets/imgs/attribution-tracking.jpg";
import notifications from "../../assets/imgs/notifications.jpg";
import { useOutletContext } from "react-router-dom";
import { motion } from "framer-motion";

const Solutions = () => {
  return (
    <FullPageContainer>
      <Container id="solutions">
        <Line style={{ minHeight: "unset" }}>
          <Title>
            Streamline <span>User Journeys</span>, Amplify{" "}
            <span>Engagement</span>.
          </Title>
        </Line>

        <Line>
          <CardWrapper
            as={motion.div}
            initial={{ height: 0 }}
            whileInView={{ height: "100%" }}
            transition={{ duration: 1, type: "spring" }}
            viewport={{ once: true }}
          >
            <ImageContainer>
              <img
                src={deeplinkImage}
                alt="Solutions Deeplink"
                style={{ width: "100%" }}
              />
            </ImageContainer>
          </CardWrapper>

          <TextWrapper
            as={motion.div}
            initial={{ translateX: "99%" }}
            whileInView={{ translateX: 0 }}
            transition={{ duration: 1, type: "spring" }}
            viewport={{ once: true }}
          >
            <h1>
              <span>Deep Linking</span>
            </h1>
            <h2>Direct Access to In-App Content</h2>
            <p>
              Deep linking allows users to navigate straight to specific content
              within your app, bypassing the app's homepage. This streamlined
              approach enhances navigation and delivers a more efficient,
              user-friendly experience.
            </p>
            <br></br>
            <SeparatorLine />
            <WhySection>
              <p>
                <b>Improved User Journey</b>: Reduce the steps needed to access
                desired content, ensuring a faster and smoother navigation
                process.
                <br></br>
                <br></br>
                <b>Higher Engagement</b>: Keep users focused and engaged by
                eliminating unnecessary detours in their journey.
                <br></br>
                <br></br>
                <b>Boost Conversions</b>: With fewer barriers, users are more
                likely to complete key actions, such as making a purchase or
                signing up.
              </p>
            </WhySection>
          </TextWrapper>
        </Line>

        <SeparatorLine />
        <Line reverse>
          <CardWrapper
            as={motion.div}
            initial={{ height: 0 }}
            whileInView={{ height: "100%" }}
            transition={{ duration: 0.6, type: "spring" }}
            viewport={{ once: true }}
          >
            <ImageContainer>
              <img
                src={crossPlatformLinking}
                alt="Cross-Platform Linking"
                style={{ width: "90%" }}
              />
            </ImageContainer>
          </CardWrapper>

          <TextWrapper
            as={motion.div}
            initial={{ translateX: "-99%" }}
            whileInView={{ translateX: 0 }}
            transition={{ duration: 1, type: "spring" }}
            viewport={{ once: true }}
          >
            <h1>
              <span>Cross-Platform Linking</span>
            </h1>
            <h2>Seamless Connectivity Across Devices</h2>
            <p>
              Cross-platform linking ensures your links work effortlessly across
              all platforms—web, iOS, and Android. This means your users enjoy a
              consistent and intuitive experience, no matter the device they’re
              using.
            </p>
            <br></br>
            <SeparatorLine />
            <WhySection>
              <p>
                <b>Effortless Access</b>: Users can easily navigate to your
                content or events, whether they're on a desktop, smartphone, or
                tablet.
                <br></br>
                <br></br>
                <b>Enhanced User Experience</b>: Simplify onboarding for new
                users by guiding them to download the app or access specific
                content without hassle.
                <br></br>
                <br></br>
                <b>Boost Engagement</b>: Keep users engaged by providing a
                frictionless journey, no matter their platform or app status.
                <br></br>
                <br></br>
                <b>Drive Conversions</b>: Improve retention, encourage app
                installs, and ultimately, increase sales with seamless,
                user-friendly links.
              </p>
            </WhySection>
          </TextWrapper>
        </Line>

        <SeparatorLine />

        <Line>
          <CardWrapper
            as={motion.div}
            initial={{ height: 0 }}
            whileInView={{ height: "100%" }}
            transition={{ duration: 0.6, type: "spring" }}
            viewport={{ once: true }}
          >
            <ImageContainer>
              <img
                src={attributionTracking}
                alt="Attribution Tracking"
                style={{ width: "90%" }}
              />
            </ImageContainer>
          </CardWrapper>
          <TextWrapper
            as={motion.div}
            initial={{ translateX: "99%" }}
            whileInView={{ translateX: 0 }}
            transition={{ duration: 1, type: "spring" }}
            viewport={{ once: true }}
          >
            <h1>
              <span>Attribution Tracking</span>
            </h1>
            <h2>Measure What Drives Your Success</h2>

            <p>
              Attribution tracking empowers businesses to identify which
              marketing channels and campaigns are driving app installs, user
              engagement, and conversions. By analyzing this data, you can gain
              deep insights into user behavior and refine your marketing
              strategies.
            </p>
            <br></br>
            <SeparatorLine />
            <WhySection>
              <p>
                <b>Channel Insights</b>: Understand the performance of key
                channels like Facebook, TikTok, and LinkedIn to determine which
                platforms deliver the best results.
                <br></br>
                <br></br>
                <b>Smart Budget Allocation</b>: Optimize your marketing spend by
                focusing resources on channels with the highest return on
                investment.
                <br></br>
                <br></br>
                <b>Data-Driven Decisions</b>: Leverage actionable insights to
                design more effective campaigns and improve overall marketing
                success.
              </p>
            </WhySection>
          </TextWrapper>
        </Line>

        <SeparatorLine />
        <Line reverse>
          <CardWrapper
            as={motion.div}
            initial={{ height: 0 }}
            whileInView={{ height: "100%" }}
            transition={{ duration: 0.6, type: "spring" }}
            viewport={{ once: true }}
          >
            <ImageContainer>
              <img
                src={notifications}
                alt="Customizable Notifications"
                style={{ width: "90%" }}
              />
            </ImageContainer>
          </CardWrapper>

          <TextWrapper
            as={motion.div}
            initial={{ translateX: "-99%" }}
            whileInView={{ translateX: 0 }}
            transition={{ duration: 1, type: "spring" }}
            viewport={{ once: true }}
          >
            <h1>
              <span>Customizable Notifications</span>
            </h1>
            <h2>Engage Your Users Like Never Before</h2>
            <p>
              Deliver personalized notifications with rich media like buttons,
              images, text, and videos. Tailor messages for specific
              platforms—iOS, Android, or Web—and ensure they appear
              automatically when users open the app. An in-app message list
              provides a convenient way for users to access unread notifications
              anytime.
            </p>
            <br></br>
            <SeparatorLine />
            <WhySection>
              <p>
                <b>Platform-Specific Relevance</b>: Deliver targeted
                notifications that resonate with users based on their platform
                for maximum impact.
                <br></br>
                <br></br>
                <b>Seamless Engagement</b>: Automatically inform and prompt
                users to take action without disrupting their experience.
                <br></br>
                <br></br>
                <b>User-Friendly Experience</b>: An in-app message list ensures
                users can easily access important notifications, keeping them
                engaged without feeling overwhelmed.
              </p>
            </WhySection>
          </TextWrapper>
        </Line>
      </Container>
    </FullPageContainer>
  );
};

export default Solutions;

const WhySection = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  justify-content: start;
  gap: 20px;

  h2 {
    color: ${lightThemeColors.darkColorFaded};
    // color: #ffffff;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;

    @media (max-width: 990px) {
      font-size: 20px;
    }
  }

  p {
    color: rgba(37, 34, 34, 0.7);
    // color: #ffffff;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 24px !important;
    text-align: left;

    @media (max-width: 990px) {
      font-size: 16px;
    }
  }
`;

const FullPageContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${lightThemeColors.background};
  position: relative;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center; /* Horizontally centers the image */
  align-items: center; /* Vertically centers the image */
  margin: 30px;
  height: 100%; /* Set height if needed for vertical centering */
`;

const Container = styled.div`
  width: 100%;
  max-width: 1512px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 80px;
  gap: 80px;
  @media (max-width: 990px) {
    padding: 20px;
    gap: 20px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 20px;

  padding: 40px 60px;
  border-radius: 10px;
  margin: 40px 0;
  @media (max-width: 990px) {
    padding: 30px 20px;
  }

  h1 {
    color: ${lightThemeColors.darkColor};
    // color: ${lightThemeColors.primaryColor};
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    // margin-bottom: 20px;

    @media (max-width: 990px) {
      font-size: 28px;
    }

    span {
      font-weight: 800;
      color: ${lightThemeColors.primaryColor};
    }
  }

  h2 {
    color: ${lightThemeColors.darkColorFaded};
    // color: #ffffff;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }

  p {
    color: rgba(37, 34, 34, 0.7);
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;

    b {
      // color: ${lightThemeColors.dark};
    }

    @media (max-width: 990px) {
      font-size: 16px;
    }
  }
  @media (max-width: 990px) {
    width: 100%;
  }
`;

const Title = styled.h1`
  color: ${lightThemeColors.darkColor};
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin-bottom: 40px;
  span {
    font-weight: 800;
    color: ${lightThemeColors.primaryColor};
  }
  @media (max-width: 990px) {
    br {
      display: none;
    }
    font-size: 30px;
    line-height: 40px;
  }
`;

const CardWrapper = styled.div`
  display: flex;
  justify-content: center; // Center horizontally
  align-items: center; // Center vertically
  width: 50%;
  overflow: hidden;
  height: 100%;
  svg {
    display: flex;
    width: 70%;
    align-items: end;
    height: auto; // Ensure the aspect ratio is maintained
    @media (max-width: 990px) {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 990px) {
    height: 100%;
    width: 80%;
  }
  @media (max-width: 550px) {
    width: 100%;
  }
`;

const Line = styled.section`
  display: flex;
  align-items: center;
  gap: 10px;

  width: 100%;
  overflow: hidden;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "initial")};
  min-height: 265px;

  @media (max-width: 990px) {
    gap: 10px;
    flex-direction: column;
  }
`;
const SeparatorLine = styled.div`
  display: flex;
  background: rgba(246, 244, 242, 1);
  min-height: 2px;
  width: 100%;
  margin: 0px 0;
  @media (max-width: 990px) {
    margin: 0;
  }
`;
